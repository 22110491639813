import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'

const Thanks = () => (
  <Layout>
    <Hero
      title="colenio classroom"
      subtitle="Unser Angebot zur Schulung von Mitarbeitern im Datenschutz"
    />
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Vielen Dank für Ihre Nachricht!</h1>
          <p>Wir melden uns sobald wie möglich bei Ihnen.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Thanks
